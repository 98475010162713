<template>
    <div class="modal-body">
        <VueDatePicker v-model="newDate" inline range :preset-dates="presetDates" @update:model-value="updateDate">
            <template #preset-date-range-button="{ label, value, presetDate }">
                <span role="button" :tabindex="0" @click="presetDate(value)" @keyup.enter.prevent="presetDate(value)" @keyup.space.prevent="presetDate(value)">
                    {{ label }}
                </span>
            </template>
        </VueDatePicker>
    </div>
</template>
<script>
    import Service from '@/services/Services';
    import eventBus from '@/services/eventBus';
    import VueDatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths } from 'date-fns';
    export default {
        props: ['key_dropdown','dataParam'],
        components: {
            VueDatePicker
        },
        data: function() {
            return {
                newDate: [],
                presetDates: [
                    { label: 'Today', value: [new Date(), new Date()] },
                    { label: 'This month', value: [startOfMonth(new Date()), endOfMonth(new Date())] },
                    { label: 'Next month', value: [startOfMonth(subMonths(new Date(), -1)), endOfMonth(subMonths(new Date(), -1))] },
                    { label: 'This year', value: [startOfYear(new Date()), endOfYear(new Date())] },
                    ],
                muParams: this.dataParam,
                dataBefore: {},
            };
        },
        methods: {
            save_update_task(){
                var thos = this;
                if (this.muParams.canUpdate != undefined) {
                    Service.post(this.$site_url+'task/update',{idTask:this.muParams.code,start:this.dataBefore.start,end:this.dataBefore.end,type:'due_date'}).then((response) => {
                        if(response.status == 'ok'){
                            thos.muParams.start = thos.dataBefore.start;
                            thos.muParams.end = thos.dataBefore.end;
                            thos.$emit('close',thos.key_dropdown);
                        }else{
                            eventBus.$emit('toast-on', response.message ,'warning');
                        }
                    });
                }else{
                    thos.muParams.start = thos.dataBefore.start;
                    thos.muParams.end = thos.dataBefore.end;
                    thos.$emit('close',thos.key_dropdown);
                }
            },
            setDate(){
                // console.log(this.newDate)
                this.newDate = [new Date(this.dataParam.start),new Date(this.dataParam.end)];
                // console.log(this.newDate)
            },
            updateDate(modelData){
                // console.log(modelData);
                this.dataBefore.start = this.$filters.formatDate(modelData[0],'YYYY-MM-DD hh:mm:ss');
                this.dataBefore.end = this.$filters.formatDate(modelData[1],'YYYY-MM-DD hh:mm:ss');
                this.save_update_task();
            }
        },
        mounted(){
            this.setDate();
        },
    };
</script>