<template>
    <div class="list_column list_add">
        <div class="act">
        </div>
        <div  class="name_2"  :style="{ 'padding-left': (25*(number_child+1)) + 'px' }">
            <input placeholder="Task Name" v-model="newTask.title" v-on:keyup.enter="save_new_task">
            <div class="act_button">
                <div @click="updatePriority($event,newTask)" class="act_mini_btn">
                    <PriorityComponent
                    :task="newTask"
                    :hide_name="newTask"
                    />
                </div>
                <div @click="updateDueDate($event,newTask)" class="act_mini_btn">
                    <DueDateComponent
                    :task="newTask"
                    :hide_name="newTask"
                    />
                </div>
                <button class="btn btn-outline-secondary" @click="cancel_new_task()">Cancel</button>
                <button class="btn btn-outline-secondary" @click="save_new_task()">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
    import Service from '@/services/Services';
    import PriorityComponent from '@/components/statis/PriorityComponent.vue';
    import DueDateComponent from '@/components/statis/DueDateComponent.vue';
    import eventBus from '@/services/eventBus';
    export default {
        name: 'ProjectListAddComponent',
        props: {
            data_status: Object,
            data_child: Object,
            number_child: Number,
        },
        components: {
            PriorityComponent,
            DueDateComponent
        },
        data: function() {
            return {
                newTask: {},
                muStatus: this.data_status,
                muChild: this.data_child,
            };
        },
        created: function() {
        },
        unmounted () {
        },
        methods: {
            cancel_new_task(){
                console.log(this.data_status,this.data_child);
                if (this.data_status != undefined && this.data_status.new_task != undefined) {
                    delete this.data_status.new_task;
                }
                if (this.data_child != undefined && this.data_child.new_task != undefined) {
                    delete this.data_child.new_task;
                    this.muChild.show_child = false;
                }
            },
            save_new_task(){
                var dataNews = this.newTask;
                if (this.data_child != undefined) {
                    dataNews.idStatus = this.data_child.status_id;
                    dataNews.idParent = this.data_child.code;
                }

                if (this.data_status != undefined) {
                    dataNews.idStatus = this.data_status.id;
                }

                
                var dataIdSpace = localStorage.getItem("idSpace");
                var dataIdProject = localStorage.getItem("idProject");
                if (dataIdSpace != undefined) { dataNews.idSpace = dataIdSpace; }
                if (dataIdProject != undefined) { dataNews.idProject = dataIdProject; }

                var thos = this;
                console.log(dataNews,this.data_child);
                // if (true) {
                    Service.post(this.$site_url+'task/add',dataNews).then((response) => {
                        if(response.status == 'ok'){
                            if (this.data_status != undefined) {
                                this.muStatus.child_number += 1;
                                this.muStatus.tasks.push(response.data);
                            }else{
                                this.muChild.child_number += 1;
                                this.muChild.child.push(response.data);
                            }
                            thos.newTask.title = '';
                            thos.newTask.priority = null;
                            delete thos.newTask.start;
                            delete thos.newTask.end;
                            eventBus.$emit('toast-on','success add task');
                        }else{
                            thos.newTask.title = '';
                            thos.newTask.priority = null;
                            delete thos.newTask.start;
                            delete thos.newTask.end;
                            eventBus.$emit('toast-on', response.message ,'warning');
                            
                        }
                    });
                // }
            },
            updatePriority(el,params){
                // params.canUpdate = true;
                eventBus.$emit('openDropdown', {target:el,
                    component:'PriorityComponent',
                    data:params
                });
            },
            updateDueDate(el,params){
                eventBus.$emit('openDropdown', {target:el,
                    component:'DueDateComponent',
                    data:params
                });
            }
        },
        mounted(){
            var thos = this;
            eventBus.$on('clickIf', function (element) {
                var dataQueryListAdd = document.querySelector('.list_add');
                var getDataOverlay = document.querySelector('.list-overlay');
                if (dataQueryListAdd != null && !dataQueryListAdd.contains(element.target) && getDataOverlay.children.length == 0){
                    thos.cancel_new_task();
                }
            });
        },
        watch: {
        }
    }
</script>
