<template>
    <div class="list-overlay">
        <div class="dropdown-mask" v-for="(data,key) in listDropdown" :key="key" :data-key="key">
            <div class="dropdown-wrapper" :style="{'opacity':(data.show?'1':'0')}">
                <div class="dropdown-body" :id="'dropdown-key-'+key" ref="target" :style="{'left':data.left+'px','top':data.top+'px'}">
                    <CalendarAddComponent
                    v-if="data.component == 'CalendarAddComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                    <PriorityComponent
                    v-if="data.component == 'PriorityComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                    <AssignComponent
                    v-if="data.component == 'AssignComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                    <TagComponent
                    v-if="data.component == 'TagComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                    <CommentComponent
                    v-if="data.component == 'CommentComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                    <DueDateComponent
                    v-if="data.component == 'DueDateComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                    <StatusComponent
                    v-if="data.component == 'StatusComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />


                    <TaskActComponent
                    v-if="data.component == 'TaskActComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                    <SpaceActComponent
                    v-if="data.component == 'SpaceActComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                    <ProjectActComponent
                    v-if="data.component == 'ProjectActComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                    <WorkspaceActComponent
                    v-if="data.component == 'WorkspaceActComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                    <CustomFieldActComponent
                    v-if="data.component == 'CustomFieldActComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                    <AssignProjectComponent
                    v-if="data.component == 'AssignProjectComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />


                    <CFtanggalComponent
                    v-if="data.component == 'CFtanggalComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                    <CFtextComponent
                    v-if="data.component == 'CFtextComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                    <CFdropdownComponent
                    v-if="data.component == 'CFdropdownComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                    <ActionAddCfComponent
                    v-if="data.component == 'ActionAddCfComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                    <AddFormCfComponent
                    v-if="data.component == 'AddFormCfComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                    <SettingActComponent
                    v-if="data.component == 'SettingActComponent'"
                    :key_dropdown="key"
                    :dataParam="data.data"
                    @close="closeDropdown"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import eventBus from '../services/eventBus';
    import CalendarAddComponent from '@/components/space/dropdown/CalendarAddComponent.vue';
    import PriorityComponent from '@/components/space/dropdown/PriorityComponent.vue';
    import AssignComponent from '@/components/space/dropdown/AssignComponent.vue';
    import TagComponent from '@/components/space/dropdown/TagComponent.vue';
    import CommentComponent from '@/components/space/dropdown/CommentComponent.vue';
    import DueDateComponent from '@/components/space/dropdown/DueDateComponent.vue';
    import StatusComponent from '@/components/space/dropdown/StatusComponent.vue';

    import TaskActComponent from '@/components/space/dropdown/TaskActComponent.vue';
    import SpaceActComponent from '@/components/space/dropdown/SpaceActComponent.vue';
    import ProjectActComponent from '@/components/space/dropdown/ProjectActComponent.vue';
    import CustomFieldActComponent from '@/components/space/dropdown/CustomFieldActComponent.vue';
    import WorkspaceActComponent from '@/components/space/dropdown/WorkspaceActComponent.vue';
    import AssignProjectComponent from '@/components/space/dropdown/AssignProjectComponent.vue';

    import ActionAddCfComponent from '@/components/space/dropdown/ActionAddCfComponent.vue';
    import AddFormCfComponent from '@/components/space/dropdown/AddFormCfComponent.vue';
    import CFtanggalComponent from '@/components/space/custom_field/CFtanggalComponent.vue';
    import CFtextComponent from '@/components/space/custom_field/CFtextComponent.vue';
    import CFdropdownComponent from '@/components/space/custom_field/CFdropdownComponent.vue';
    import SettingActComponent from '@/components/space/dropdown/SettingActComponent.vue';
    export default {
        components: {
            CalendarAddComponent,
            PriorityComponent,
            AssignComponent,
            TagComponent,
            CommentComponent,
            DueDateComponent,
            StatusComponent,
            TaskActComponent,
            CFtanggalComponent,
            CFtextComponent,
            CFdropdownComponent,
            SettingActComponent,
            ActionAddCfComponent,
            AddFormCfComponent,
            SpaceActComponent,
            ProjectActComponent,
            CustomFieldActComponent,
            WorkspaceActComponent,
            AssignProjectComponent,
        },
        props: {
        },
        data() {
            return {
                listDropdown:[],
                isOpen: false,
                left: 0,
                top: 0,
                content: '',
            };
        },
        methods: {
            nextSetting(){
                var idx = this.listDropdown.length-1;
                var data_body = document.querySelector('#dropdown-key-'+idx);
                if (data_body != undefined) {
                    var dataWidth = data_body.clientWidth;
                    var dataHeight = data_body.clientHeight;
                    var windowWidth = window.innerWidth;
                    var windowHeight = window.innerHeight;

                    this.listDropdown[idx].left = this.listDropdown[idx].target.clientX;
                    this.listDropdown[idx].top = this.listDropdown[idx].target.clientY;
                    // console.dir(data_body);
                    // console.log({dataWidth:dataWidth,dataHeight:dataHeight,windowWidth:windowWidth,windowHeight:windowHeight});
                    // console.log((this.listDropdown[idx].left + dataWidth) > windowWidth,windowWidth - (dataWidth + 10),this.listDropdown,(-this.listDropdown[idx].target.offsetX));
                    if ((this.listDropdown[idx].top + dataHeight) > windowHeight) {
                        this.listDropdown[idx].top = windowHeight - (dataHeight + 10);
                    }else{
                        this.listDropdown[idx].top += (-this.listDropdown[idx].target.offsetY+30);
                    }
                    if ((this.listDropdown[idx].left + dataWidth) > windowWidth) {
                        this.listDropdown[idx].left = windowWidth - (dataWidth + 10);
                    }else{
                        this.listDropdown[idx].left += (-this.listDropdown[idx].target.offsetX);
                    }
                    this.listDropdown[idx].show = true;
                }
            },
            closeDropdown(idx){
                this.listDropdown.splice(idx, 1);
                this.setGlobal();
            },
            setGlobal(){
            }
        },
        mounted() {
            var thos = this;
            eventBus.$on('clickIf', function (element) {
                // console.dir(element.target);
                if (element.target.classList != undefined) {
                    if (element.target.classList.contains('dropdown-mask')){
                        thos.listDropdown.splice(element.target.dataset.key, 1);
                        thos.setGlobal();
                    }
                }
            });
            eventBus.$on('dropdown-resize', function () {
                setTimeout(function() {
                    if(thos.listDropdown.length > 0){
                        thos.nextSetting();
                    }
                }, 10);
            });
            eventBus.$on('openDropdown', function (opt) {
                // console.log(opt.target.srcElement,opt.target.srcElement.offsetLeft,opt.target.srcElement.offsetTop);
                setTimeout(function() {
                    thos.listDropdown.push({'left':opt.target.clientX,'top':opt.target.clientY,'component':opt.component,'data':opt.data,'target':opt.target,'show':false});
                    // thos.setGlobal();
                    setTimeout(function() {
                        if(thos.listDropdown.length > 0){
                            thos.nextSetting();
                        }
                    }, 100);
                }, 10);
            });
        },
    };
</script>