import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import filters from './services/Filters';
import Vue3ConfirmDialog from '@zapadale/vue3-confirm-dialog';
import ToastPlugin from 'vue-toast-notification';

import 'vue-toast-notification/dist/theme-bootstrap.css';
import("./assets/css/tailwind.min.css");
import("./assets/css/bootstrap.min.css");
import("./assets/icon-custom/style.css");
import("./assets/css/style.css");
import("./assets/css/skeleton.css");
import("./assets/css/global.scss");

import json from './dummy/board.json';

var app = createApp(App);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$filters = filters;
app.config.globalProperties.$client_id = '459070172919-gji5jpmgummll2m02prl5468kjef502j.apps.googleusercontent.com';
app.config.globalProperties.$api_key = 'AIzaSyB18QRSlZsuC_93WMPPVt2U4QiMkI2oREQ';

var url_http = window.location.origin+'/';
var socket_url = 'https://socket.sortilo.com';
if (url_http.includes('localhost')) {
	// url_http = 'https://sortilo.com/';
	url_http = 'http://localhost/ci_clickup/';
	// socket_url = 'http://localhost:3000';
}

app.config.globalProperties.$site_url = url_http+'api/';
app.config.globalProperties.$socket_url = socket_url;

app.config.globalProperties.$board_dummy = json;
app.config.globalProperties.$globalDropdown = [];
app.use(router);
app.use(ToastPlugin);
app.use(Vue3ConfirmDialog);
app.mount('#app');