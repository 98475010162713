<template>
    <div class="modal-task" v-if="isOpen">
        <div class="modal-space-wrapper">
            <div class="modal-space">
                <div class="space-body">
                    <div class="space-act-close">
                        <h5>Setting User</h5>
                        <span class="om-close" @click="closeBack()"></span>
                    </div>
                    <div class="space-act" style="margin-top: 20px;">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Name</label>
                            <div class="space-name">
                                <input type="email" class="form-control" id="exampleInputEmail1" v-model="newForm.name" aria-describedby="emailHelp" placeholder="e.g. Robert D JJ">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Connect Google</label>
                            <div class="space-name">
                                <button class="btn-close" v-if="newForm.connect_google == null" @click="doConnectGoogle()">Lets Connect</button>
                                <button class="btn-done" v-if="newForm.connect_google != null" @click="revokeConnectGoogle()">Connected</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="space-footer">
                    <button :disabled="newForm.name == ''" @click="updateUser()">Updated</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import eventBus from '@/services/eventBus';
    import Service from '@/services/Services';
    export default {
        components: {
        },
        props: {
        },
        data() {
            return {
                isOpen: false,
                isUpdate: false,
                openInvite: false,
                selectParam:{},
                newForm: {
                    name:'',
                    oauthToken:'',
                    connect_google:null
                },
                googlePicker:{
                    pickerApiLoaded: false,
                    developerKey: this.$api_key,
                    clientId: this.$client_id,
                    scope: "https://www.googleapis.com/auth/drive",
                    oauthToken: null
                },
            };
        },
        methods: {
            closeBack(){
                this.isOpen = false;
            },
            getDetailUser(){
                var thos = this;
                Service.get(this.$site_url+'member/data',{}).then((response) => {
                    if(response.status == 'ok'){
                        thos.newForm = response.data;
                    }
                });
            },
            updateUser(autoClose = false){
                var thos = this;
                Service.post(this.$site_url+'member/data',this.newForm).then((response) => {
                    if(response.status == 'ok'){
                        // thos.newForm = response.data;
                        thos.newForm.connect_google = thos.newForm.oauthToken;
                        if (autoClose) {
                            thos.closeBack();
                        }
                    }
                });
            },
            revokeConnectGoogle(){
                var thos = this;
                this.$confirm({
                    title: 'Confirm your action',
                    message: 'Are you sure remove google?',
                    disableKeys: false,
                    auth: false,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    callback: confirm => {
                        if (confirm) {
                            Service.post(this.$site_url+'member/remove',{'field':'connect_google'}).then((response) => {
                                if(response.status == 'ok'){
                                    thos.newForm.connect_google = null;
                                }
                            });
                        }
                    }
                })
            },
            async doConnectGoogle(){
                await window.gapi.load("auth2", () => {
                    // console.log("Auth2 Loaded");
                    window.gapi.auth2.authorize({
                        access_type: 'offline',
                        client_id: this.googlePicker.clientId,
                        scope: this.googlePicker.scope,
                        immediate: false,
                        plugin_name: "Task Management Local"
                    },
                    this.handleAuthResult
                    );
                });
                window.gapi.load("picker", () => {
                });
            },
            handleAuthResult(authResult) {
                console.log(authResult);
                if (authResult && !authResult.error) {
                    this.newForm.oauthToken = authResult.access_token;
                    this.updateUser();
                }
            }
        },
        mounted() {
            var thos = this;
            eventBus.$on('open-modal-setting', function (params) {
                thos.isOpen = true;
                thos.selectParam = params;
                thos.getDetailUser();
            });

            eventBus.$on('clickIf', function (element) {
                // console.dir(element.target);
                if (element.target.classList != undefined) {
                    if (element.target.classList.contains('modal-space-wrapper')){
                        thos.closeBack();
                    }
                }
            });

            eventBus.$on('close-modal-task', function () {
                thos.closeBack();
            });
        },
    };
</script>
