import emitter from 'tiny-emitter/instance'

const eventListeners = new Map();

export default {
  $on: (event, listener) => {
    emitter.on(event, listener);
    // Simpan listener dalam map
    if (!eventListeners.has(event)) {
      eventListeners.set(event, []);
    }
    eventListeners.get(event).push(listener);
  },
  $once: (...args) => emitter.once(...args),
  $off: (event, listener) => {
    emitter.off(event, listener);
    if (eventListeners.has(event)) {
      const listeners = eventListeners.get(event);
      const index = listeners.indexOf(listener);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    }
  },
  $emit: (...args) => emitter.emit(...args),
  hasListeners: (event) => {
    return eventListeners.has(event) && eventListeners.get(event).length > 0;
  }
}