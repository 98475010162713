<template>
    <!-- <div class="modal-header">
        <div class="list-assign flex" >
            <span class="om-traffic-signal"></span>
            <span>Clear</span>
        </div>
    </div> -->
    <div class="modal-body">
        <div class="list-assign flex" v-for="(item,key) in listTeam" :key="key" @click="setPriority(item)" :class="{'assign-active':checkAssing(item)}">
            <span class="one-alfabet green" :style="{'background-color':'#'+item.avatar_color,'color':'white'}">{{$filters.twoCharacter(item.name==''?item.email:item.name)}}</span>
            <span>{{item.name==''?item.email:item.name}}</span>
        </div>
    </div>
</template>
<script>
    import Service from '@/services/Services';
    export default {
        props: ['key_dropdown','dataParam'],
        data: function() {
            return {
                newTask: {
                    end: this.dataParam.date,
                },
                muParams: this.dataParam,
                listTeam: []
            };
        },
        methods: {
            setPriority(param){
                console.log(param);
                var thos = this;

                var getIndex = this.muParams.team.findIndex(x => x.user_id === param.user_id);
                if (getIndex == -1) {
                    console.log({idProject:this.muParams.id,user_id:param.user_id,type:'add'});
                    Service.post(this.$site_url+'project/assign',{idProject:this.muParams.id,user_id:param.user_id,type:'add'}).then((response) => {
                        if(response.status == 'ok'){
                            thos.muParams.team.push(param);
                        }
                    });
                }else{
                    Service.post(this.$site_url+'project/assign',{idProject:this.muParams.id,user_id:param.user_id,type:'remove'}).then((response) => {
                        if(response.status == 'ok'){
                            thos.muParams.team.splice(getIndex,1);
                        }
                    });
                }
            },
            checkAssing(param){
                var getIndex = this.muParams.team.findIndex(x => x.user_id === param.user_id);
                if (getIndex == -1) {
                    return false;
                }else{
                    return true;
                }
            },
            getListAssign(){
                this.listTeam = this.dataParam.list_team;
            }
        },
        mounted() {
            this.getListAssign();
        },
    };
</script>