<template>
    <div class="modal-task" v-if="isOpen">
        <div class="modal-space-wrapper">
            <div class="modal-space modal-chat">
                <div class="space-body">
                    <div class="space-act-close">
                        <h5>Start New Message</h5>
                        <span class="om-close" @click="closeBack()"></span>
                    </div>
                    <div v-if="!newGroupTeam">
                        <!-- <button class="btn-done" @click="startNewChat()" style="margin-top: 10px">Create Start New Group</button>
                        <div class="list_chat_personal" v-if="filteredPersonal.length">
                            <div class="search">
                                <input type="" name="" placeholder="Search Name Team" v-model="listSearch">
                            </div>
                            <div class="list_team">
                                <span class="one-alfabet two-alfabet" v-for="(item,key) in filteredPersonal" :key="key" :style="{'background-color':'#'+item.avatar_color,'color':'white'}" :title="item.name==''?item.email:item.name">
                                    {{$filters.twoCharacter(item.name==''?item.email:item.name)}}
                                </span>
                            </div>
                        </div> -->
                        <div class="listView chatListGroup">
                            <div class="content_list">
                                <div class="vl_head">
                                    <div class="name" style="flex:1;max-width: unset;">Name</div>
                                    <div class="act_2"><span class="om-home"></span></div>
                                </div>
                                <div class="vl_body">
                                    <div class="list_column list_add" v-for="(item,key) in listProject" :key="key">
                                        <div class="name" style="flex:1;max-width: unset;">
                                            {{item.name==''?'No Name':item.name}}
                                            <span class="dot">•</span>
                                            <span class="space">in {{item.space_name}}</span>
                                        </div>
                                        <div class="act_2">
                                            <button :class="{'btn-close':item.chat_group,'btn-done':!item.chat_group}" style="margin-right: 0px;" @click="addChatGroup(item)">Start Chat</button>
                                            <button class="btn-done" @click="startNewChat(item)" style="margin-left: 8px;">Create New Group</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="newGroupTeam">
                        <div class="form-group" style="margin-top:20px">
                            <label for="exampleInputEmail1">Name Chat Project</label>
                            <div class="space-name">
                                <input type="text" name="text" v-model="newForm.name" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="e.g. Team Internal">
                            </div>
                        </div>
                        <div class="form-group list-team-assign">
                            <div class="list-assign flex" v-for="(item,key) in listAssign" :key="key" @click="setAssign(item)" :class="{'assign-active':checkAssing(item)}" >
                                <span class="one-alfabet green" :style="{'background-color':'#'+item.avatar_color,'color':'white'}">{{$filters.twoCharacter(item.name==''?item.email:item.name)}}</span>
                                <span class="name">{{item.name==''?item.email:item.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="space-footer">
                    <button class="btn-close"  v-if="newGroupTeam" @click="newGroupTeam = !newGroupTeam">Back</button>
                    <button class="btn-close" @click="closeBack()">Close Modal</button>
                    <button class="btn-done" v-if="newGroupTeam" @click="doInvite()" :disabled="newForm.name == ''">Invite</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import eventBus from '@/services/eventBus';
    import Service from '@/services/Services';
    export default {
        components: {
        },
        props: {
        },
        data() {
            return {
                isOpen: false,
                newGroupTeam: false,
                listTeam: [],
                listAssign: [],
                listProject: [],
                selectProject : {},
                listSearch : '',
                newForm: {
                    email:'',
                    idWorkspace: '',
                },
            };
        },
        computed: {
            filteredPersonal() {
                return this.listTeam.filter(item => {
                    const getName = item.name.toLowerCase().includes(this.listSearch.toLowerCase());
                    return (getName && item.is_me == false && item.chat_personal == false);
                });
            },
        },
        methods: {
            closeBack(){
                this.isOpen = false;
                this.newGroupTeam = false;
            },
            startNewChat(item){
                this.newGroupTeam = true;
                this.selectProject = item;
                this.listAssign.map((v)=>{
                    delete v.assign;
                })
            },
            checkAssing(param){
                if (param.assign == undefined) {
                    return false;
                }else{
                    return true;
                }
            },
            setAssign(param){
                if (param.assign != undefined) {
                    delete param.assign;
                }else{
                    param.assign = true;
                }
            },
            addChatGroup(item){
                if (!item.chat_group) {
                    var data_comment = {};
                    data_comment.idProject = item.id;
                    Service.post(this.$site_url+'comment/add_group',data_comment).then((response) => {
                        if(response.status == 'ok'){
                            eventBus.$emit('chat-new-project',response.data);
                            this.closeBack();
                        }
                    });
                }
            },
            getListTeams(){
                var thos = this;
                // console.log(params);
                Service.post(this.$site_url+'space/team',{format:'chat_personal'}).then((response) => {
                    if(response.status == 'ok'){
                        thos.listTeam = response.data;
                        thos.listAssign = response.data;
                        thos.newForm.idWorkspace = response.workspace_id;
                    }
                });
            },
            getListProject(){
                var thos = this;
                // console.log(params);
                Service.post(this.$site_url+'workspace/list_project',{format:'chat_group'}).then((response) => {
                    if(response.status == 'ok'){
                        thos.listProject = response.data;
                    }
                });
            },
            doInvite(){
                var data_comment = {};
                data_comment.name = this.newForm.name;
                data_comment.idProject = this.selectProject.id;
                data_comment.assign = [];
                this.listAssign.map((v)=>{
                    if (v.assign != undefined) {
                        data_comment.assign.push(v.user_id);
                    }
                })
                data_comment.assign = JSON.stringify(data_comment.assign);

                Service.post(this.$site_url+'comment/add_group',data_comment).then((response) => {
                    if(response.status == 'ok'){
                        eventBus.$emit('chat-new-project',response.data);
                        this.closeBack();
                        this.newForm.name = '';
                    }
                });
            },
            deleteTeam(param){
                var thos = this;
                this.$confirm({
                    title: 'Confirm your action',
                    message: 'Are you sure?',
                    disableKeys: false,
                    auth: false,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    callback: confirm => {
                        if (confirm) {
                            Service.post(thos.$site_url+'space/remove_team',{id:param.id,idWorkspace:thos.newForm.idWorkspace}).then((response) => {
                                if(response.status == 'ok'){
                                    thos.getListTeams();
                                }
                            });
                        }
                    }
                })
            }
        },
        mounted() {
            var thos = this;
            this.newGroupTeam = false;
            eventBus.$on('open-modal-chat', function (params) {
                thos.isOpen = true;
                thos.listTeam = params.listTeam;
                thos.listAssign = params.listTeam;
                thos.getListProject();
            });

            eventBus.$on('clickIf', function (element) {
                if (element.target.classList != undefined) {
                    if (element.target.classList.contains('modal-space-wrapper')){
                        thos.closeBack();
                    }
                }
            });

            eventBus.$on('close-modal-task', function () {
                thos.closeBack();
            });
        },
    };
</script>
