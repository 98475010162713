import moment from 'moment';

const filters = {
  formatDate(input,format = 'MM/DD/YYYY hh:mm') {
    return moment(String(input)).format(format);
  },
  ifThisYear(input,format = 'MMM DD') {
    if (moment(input).isSame(new Date(), 'year')) {
      return moment(String(input)).format(format);
    }else{
      return moment(String(input)).format('YYYY '+format);
    }
  },
  formatPrice(input) {
    return Number(input).toLocaleString();
  },
  formatFromNow(input) {
    return moment(input).fromNow();
  },
  firstCharacter(input){
    return Array.from(input)[0];
  },
  twoCharacter(input){
    var listInput = input.split(" ");
    return (listInput.length < 2?input.substring(0,2):listInput[0][0]+listInput[1][0]);
  },
  nameFirst(input){
    var listInput = input.split(" ");
    return (listInput.length < 2?input:listInput[0]);
  },
  checkAuth(){
    var dataToken = localStorage.getItem("OToken");
    return (dataToken == null?false:true);
  },
  getInfo(name){
    var dataUser = localStorage.getItem("user");
    return (dataUser[name] != undefined?dataUser[name]:'');
  },
  make_random(length = 10) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
}
export default filters;