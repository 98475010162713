<template>
    <div class="modal-body">
        <div class="list-priority flex" @click="openNewProject()">
            <span class="om-circle-add"></span>
            <span>Add Project</span>
        </div>
        <div class="list-priority flex" @click="editSpace()">
            <span class="om-pencil"></span>
            <span>Edit</span>
        </div>
        <div class="list-priority flex" @click="editTeams()">
            <span class="om-users"></span>
            <span>Teams</span>
        </div>
        <div class="list-priority flex"  @click="deleteSpace()">
            <span class="om-delete"></span>
            <span>Delete</span>
        </div>
    </div>
</template>
<script>
    import Service from '@/services/Services';
    import eventBus from '@/services/eventBus';
    export default {
        props: ['key_dropdown','dataParam'],
        data: function() {
            return {
                newTask: {
                    end: this.dataParam.date,
                },
                muParams: this.dataParam,
            };
        },
        methods: {
            openNewProject(){
                this.$emit('close',this.key_dropdown);
                eventBus.$emit('open-modal-project',this.dataParam);
            },
            editSpace(){
                this.$emit('close',this.key_dropdown);
                this.muParams.isUpdate = true;
                eventBus.$emit('open-modal-space',this.muParams);
            },
            editTeams(){
                // this.$emit('close',this.key_dropdown);
                // eventBus.$emit('open-modal-team',this.dataParam);
                var dataIdWorkspace = localStorage.getItem("idWorkspace");
                this.$router.push({name: 'team', params: {idWorkspace:dataIdWorkspace}});
                this.$emit('close',this.key_dropdown);
            },
            deleteSpace(){
                var thos = this;
                this.$confirm({
                    title: 'Confirm your action',
                    message: 'Are you sure?',
                    disableKeys: false,
                    auth: false,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    callback: confirm => {
                        if (confirm) {
                            Service.post(this.$site_url+'space/delete',{idSpace:this.muParams.id}).then((response) => {
                                if(response.status == 'ok'){
                                    eventBus.$emit('space-update-list',{idSpace:this.muParams.id});
                                    thos.$emit('close',thos.key_dropdown);
                                }
                            });
                        }
                    }
                })
            }
        },
    };
</script>