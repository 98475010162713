<template>
    <div class="modal-preview" v-if="isOpen">
        <div class="modal-space-wrapper">
            <div class="modal-space modal-preview-contain">
                <div class="space-body">
                    <iframe width="auto" height="100%" style="margin:auto;" :src="dataFiles.preview"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import eventBus from '@/services/eventBus';
    import Service from '@/services/Services';
    export default {
        components: {
        },
        props: {
        },
        data() {
            return {
                isOpen: false,
                dataFiles: {},
            };
        },
        methods: {
            closeBack(){
                this.isOpen = false;
            },
            createSpace(){
                var thos = this;
                Service.post(this.$site_url+'space/add',this.newSpace).then((response) => {
                    if(response.status == 'ok'){
                        eventBus.$emit('space-get',response.data);
                        thos.closeBack();
                    }
                });
            }
        },
        mounted() {
            var thos = this;
            eventBus.$on('open-modal-preview', function (files) {
                thos.isOpen = true;
                thos.dataFiles = files;
            });

            eventBus.$on('clickIf', function (element) {
                // console.dir(element.target);
                if (element.target.classList != undefined) {
                    if (element.target.classList.contains('modal-space-wrapper')){
                        thos.closeBack();
                    }
                }
            });

            eventBus.$on('close-modal-task', function () {
                thos.closeBack();
            });
        },
    };
</script>
