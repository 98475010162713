<template>
  <div class="header" v-if="hasLogin">
    <div class="cursor-pointer" @click="$router.push({name: 'board'})">
      <span class="om-home"></span>
    </div>
    <div>
      <input type="" name="" placeholder="Search" style="text-align: center;color: white;" v-if="!waitLoading">
    </div>
    <div>
      <!-- <div class="button-icon btn-padding">
        <span class="om-circle-add"></span>
        <span>New</span>
      </div>
      <div class="sparator"></div>
      <div class="button-icon">
        <span class="om-circle-check"></span>
      </div>
      <div class="button-icon">
        <span class="om-to-do-list"></span>
      </div>
      <div class="button-icon">
        <span class="om-video"></span>
      </div>
      <div class="button-icon">
        <span class="om-alarm"></span>
      </div>
      <div class="button-icon">
        <span class="om-document"></span>
      </div>
      <div class="button-icon">
        <span class="om-dots"></span>
      </div> -->
      <div class="button-icon">
        <div @click.stop="actSetting($event)" class="act_mini_btn" style="margin-right:0px;">
          <span class="om-settings"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="body" v-if="hasLogin">
    <!-- <div class="notification">
      <div>
        <span class="om-circle-check"></span>
        <p>Do you want to enable browser notifications?</p>
        <button class="btn">Enable</button>
        <button class="btn">Remind me</button>
      </div>
      <div>
        <span class="om-circle-check"></span>
      </div>
    </div> -->
    <div class="body-slice">
      <SideLeft v-if="!hideSideLeft" :space="listSpace" :notification="listNotification" :workspace="listWorkspace"/>
      <div class="right">
        <router-view :key="$route.fullPath"/>
      </div>
    </div>
  </div>
  <div v-if="hasLogin">
    <DropdownComponent/>
    <ModalTaskComponent/>
    <ModalCustomFieldComponent/>
    <ModalSpaceComponent/>
    <ModalProjectComponent/>
    <ModalChatComponent/>
    <ModalSettingComponent/>
    <ModalPreviewComponent/>
    <vue3-confirm-dialog/>
  </div>
  <div class="" v-if="!hasLogin">
    <router-view v-if="!waitLoading"/>
  </div>
</template>
<script>
  import SideLeft from '@/components/panel/SideLeft.vue';
  import DropdownComponent from '@/components/DropdownComponent.vue';
  import ModalTaskComponent from '@/components/modal/ModalTaskComponent.vue';
  import ModalCustomFieldComponent from '@/components/modal/ModalCustomFieldComponent.vue';
  import ModalSpaceComponent from '@/components/modal/ModalSpaceComponent.vue';
  import ModalProjectComponent from '@/components/modal/ModalProjectComponent.vue';
  import ModalSettingComponent from '@/components/modal/ModalSettingComponent.vue';
  import ModalChatComponent from '@/components/modal/ModalChatComponent.vue';
  import ModalPreviewComponent from '@/components/modal/ModalPreviewComponent.vue';
  import Service from './services/Services';
  import eventBus from './services/eventBus';
  import {useToast} from 'vue-toast-notification';
  import { io } from 'socket.io-client';
  export default {
    name: 'App',
    components: {
      SideLeft,
      DropdownComponent,
      ModalTaskComponent,
      ModalCustomFieldComponent,
      ModalSpaceComponent,
      ModalProjectComponent,
      ModalSettingComponent,
      ModalChatComponent,
      ModalPreviewComponent
    },
    data: function() {
      return {
        socket: null,
        hasLogin: false,
        waitLoading: true,
        hideSideLeft: false,
        panelLeft: 300,
        idWorkspace : 11,
        listWorkspace: [],
        listSpace: [],
        listUser: [],
        listNotification: [],
        currentVersion: '0.1'
        // currentVersion: process.env.VUE_APP_VERSION
      };
    },
    unmounted () {
    },
    methods: {
      routeApp(name = ''){
        if (name == 'home') {
          this.$router.push({name: 'home'});
        }
        // console.log(name);
      },
      async getListWorkspace(){
        await Service.get(this.$site_url+'workspace/get_list',{}).then((response) => {
          if(response.status == 'ok'){
            this.listWorkspace = response.data;
            var dataIdWorkspace = localStorage.getItem("idWorkspace");
            if (dataIdWorkspace == null) {
              localStorage.setItem("idWorkspace", response.data[0].id);
            }else{
              var indexWS = this.listWorkspace.findIndex(x => x.id === dataIdWorkspace);
              if (indexWS == -1) {
                localStorage.setItem("idWorkspace", response.data[0].id);
              }
            }
          }
          return true;
        });
      },
      async getListUser(){
        var thos = this;
        var dataIdSpace = localStorage.getItem("idSpace");
        await Service.post(this.$site_url+'space/team',{idSpace:dataIdSpace}).then((response) => {
          if(response.status == 'ok'){
            thos.listUser = response.data;
          }
        });
      },
      async getListSpace(){
        var thos = this;
        await Service.get(this.$site_url+'space/get_list',{}).then((response) => {
          if(response.status == 'ok'){
            thos.listSpace = response.data;
            thos.checkSelectProject(thos.listSpace);
          }else{
            setTimeout(function() {
              thos.showHideLoader(false);
            }, 100);
          }
          // thos.hideSideLeft = false;
        });
      },
      async getUser(){
        var thos = this;
        await Service.get(thos.$site_url+'member/data',{}).then((response) => {
          if(response.status == 'ok'){
            console.log('join room',response.data.id);
            this.socket.emit('join room', response.data.id);
          }
        });
      },
      getListNotification(){
        Service.get(this.$site_url+'workspace/notification').then((response) => {
          if(response.status == 'ok'){
            this.listNotification = response.data;
          }
        });
      },
      checkSelectProject(listSpace){
        var thos = this;
        if (this.$route.params.idProject != undefined) {
          localStorage.setItem("idProject", this.$route.params.idProject);
        }else{
          var dataIdProject = localStorage.getItem("idProject");
          if (dataIdProject == null) {
            if (listSpace[0] != undefined) {
              localStorage.setItem("idProject", listSpace[0].child[0].id);
            }
          }
        }

        if (this.$route.params.idSpace != undefined) {
          localStorage.setItem("idSpace", this.$route.params.idSpace);
        }else{
          var dataIdSpace = localStorage.getItem("idSpace");
          if (dataIdSpace == null) {
            if (listSpace[0] != undefined) {
              localStorage.setItem("idSpace", listSpace[0].id);
            }
          }
        }
        setTimeout(function() {
          thos.showHideLoader(false);
        }, 100);
      },
      actSetting(el){
        eventBus.$emit('openDropdown', {target:el,
          component:'SettingActComponent',
          data:{}
        });
      },
      showHideLoader(varbool){
        var loaderFirst = document.querySelector("#loader-first");
        if (varbool == true) {
          loaderFirst.style.display = 'flex';
        }else{
          loaderFirst.style.display = 'none';
        }
      },
      setGoogleApi(){
        let gDrive = document.createElement("script");
        gDrive.setAttribute("type", "text/javascript");
        gDrive.setAttribute("src", "https://apis.google.com/js/api.js");
        document.head.appendChild(gDrive);

        let gDriveClient = document.createElement("script");
        gDriveClient.setAttribute("type", "text/javascript");
        gDriveClient.setAttribute("src", "https://apis.google.com/js/client.js");
        document.head.appendChild(gDriveClient);
      },
      doLogout(){
        var thos = this;
        this.$confirm({
          title: 'Confirm your action',
          message: 'Are you sure?',
          disableKeys: false,
          auth: false,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: confirm => {
            if (confirm) {
              thos.logoutAllUsers();
            }
          }
        })
      },
      logoutAllUsers(){
        localStorage.removeItem("user");
        localStorage.removeItem("OToken");
        localStorage.removeItem("idSpace");
        localStorage.removeItem("idProject");
        this.$router.push({name: 'login'});
        eventBus.$emit('hide-side', true);
        this.hasLogin = false;
      },
      showToast(message = 'Something went wrong!',type = 'success'){
        var $toast = useToast();
        $toast.open({
          message: message,
          type: type,
        });
      },
      checkVersion() {
        const storedVersion = localStorage.getItem('appVersion');
        if (storedVersion && storedVersion !== this.currentVersion) {
          // this.logoutAllUsers();
        }
        localStorage.setItem('appVersion', this.currentVersion);
      },
      async afterLogin(){
        this.hasLogin = true;
        await this.getListWorkspace();
        await this.getListUser();
        await this.getListSpace();
        await this.getUser();
        this.waitLoading = false;
        this.getListNotification();
      }
    },
    async created() {
      var loaderFirst = document.querySelector("#loader-first");
      document.addEventListener('click', event => {
        eventBus.$emit('clickIf', event);
      })
      this.socket = io(this.$socket_url);
      this.socket.on('notification', (msg) => {
        console.log('notif',msg);
        // this.listNotification[msg.type_notif] = response.data;
        eventBus.$emit('add-notif',msg);
      });

      if (this.$filters.checkAuth()) {
        this.afterLogin();
      }else{
        this.waitLoading = false;
        setTimeout(function() {
          loaderFirst.style.display = 'none';
        }, 1000);
      }
    },
    mounted(){
      var thos = this;
      // var dataSession = localStorage.getItem("OToken");
      // if (dataSession != undefined || dataSession != null) {
      this.showHideLoader(true);
      this.setGoogleApi();
      if (this.$filters.checkAuth()) {
        this.checkVersion();
      }

      // setTimeout(function() {
      //   if (thos.$route.meta.front_assets != undefined) {
      //     thos.hasLogin = false;
      //   }
      //   console.log(thos.$route);
      // }, 100);
      eventBus.$on('toast-on', function (message = 'Something went wrong!',type = 'success') {
        thos.showToast(message,type);
      });

      eventBus.$on('hide-side', function (params = true) {
        thos.hideSideLeft = params;
        console.log('tidak ada side',thos.hideSideLeft);
        if (thos.$route.meta.front_assets != undefined) {
          thos.hasLogin = false;
        }else{
          thos.hasLogin = true;
        }
      });
      eventBus.$on('oncheck-login',function(){
        thos.afterLogin();
      });
      eventBus.$on('space-get',function(params){
        thos.listSpace.push(params);
      });
      eventBus.$on('get-global-variable',function(params, callback){
        // console.log('Data dari ChildComponent:', params);
        if (params.variable != undefined) {
          if (params.variable == 'notification') {
            callback(thos.listSpace);
          }else{
            callback([]);
          }
        }else{
          callback([]);
        }
      });
      eventBus.$on('get-global-space',function(params, callback){
        console.log('Data dari ChildComponent:', params);
        if (thos.listSpace.length == 0) {
          Service.get(thos.$site_url+'space/get_list',{}).then((response) => {
            if(response.status == 'ok'){
              thos.listSpace = response.data;
              callback(thos.listSpace);
            }
          });
        }else{
          callback(thos.listSpace);
        }
      });
      eventBus.$on('get-global-user',function(params, callback){
        // console.log('Data dari ChildComponent:', params);
        if (thos.listUser.length == 0) {
          var dataIdSpace = localStorage.getItem("idSpace");
          Service.post(thos.$site_url+'space/team',{idSpace:dataIdSpace}).then((response) => {
            if(response.status == 'ok'){
              thos.listUser = response.data;
              callback(thos.listUser);
            }
          });
        }else{
          callback(thos.listUser);
        }
      });
      eventBus.$on('space-update-list',function(params){
        thos.getListSpace();
        if (params.idProject != undefined) {
          var dataIdProject = localStorage.getItem("idProject");
          if (dataIdProject != null && params.idProject == dataIdProject) {
            thos.routeApp('home');
          }
        }
        if (params.idSpace != undefined) {
          var dataidSpace = localStorage.getItem("idSpace");
          if (dataidSpace != null && params.idSpace == dataidSpace) {
            thos.routeApp('home');
          }
        }
      });
    },
    watch: {
    }
  }
</script>