<template>
    <div class="modal-body">
        <div class="list-priority flex" @click="doSetting()">
            <span class="om-settings"></span>
            <span>Setting</span>
        </div>
    </div>
    <div class="modal-footer">
        <div class="list-priority flex" @click="doLogout()">
            <span class="om-log-out"></span>
            <span>Logout</span>
        </div>
    </div>
</template>
<script>
    // import Service from '@/services/Services';
    import eventBus from '@/services/eventBus';
    export default {
        props: ['key_dropdown','dataParam'],
        data: function() {
            return {
                newTask: {
                    end: this.dataParam.date,
                },
                muParams: this.dataParam,
                selectNumber:'',
            };
        },
        methods: {
            doSetting(){
                eventBus.$emit('open-modal-setting', true);
                this.$emit('close',this.key_dropdown);
            },
            logoutAllUsers(){
                localStorage.removeItem("user");
                localStorage.removeItem("OToken");
                localStorage.removeItem("idSpace");
                localStorage.removeItem("idProject");
                localStorage.removeItem("idWorkspace");
                this.$router.push({name: 'login'});
                eventBus.$emit('hide-side', true);
                this.hasLogin = false;
            },
            doLogout(){
                var thos = this;
                this.$confirm({
                    title: 'Confirm your action',
                    message: 'Are you sure?',
                    disableKeys: false,
                    auth: false,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    callback: confirm => {
                        if (confirm) {
                            thos.logoutAllUsers();
                        }
                    }
                })
            }
        },
    };
</script>