<template>
    <div class="top-right" id="headerTask" @keydown.esc="closeModal()" tabindex="0">
        <div class="tab_two">
            <BreadCrumbComponent :bread_crumb="dataBread"></BreadCrumbComponent>
            <!-- <button ><span class="om-grid" @click="showTabLeft = !showTabLeft"></span></button> -->
        </div>
        <div class="tab_two">
            <span>Created on {{dataTask.create_date}}</span>
            <button class="btn btn-primary btn-show">Share</button>
            <button ><span class="om-more"></span></button>
            <button @click="closeModal()"><span class="om-close"></span></button>
        </div>
    </div>
    <div class="taskView">
        <div class="tab-left" v-if="showTabLeft"></div>
        <div class="tab-content">
            <div class="body">
                <h1>{{dataTask.title}}</h1>
                <div class="task-field">
                    <div class="task-fields">
                        <div class="task-field-label">
                            <span class="om-prog-0"></span>
                            <p>Status</p>
                        </div>
                        <div @click="updateStatus($event,dataTask)" class="act_mini_btn act_status">
                            <div class="status_form"  :style="{ 'background-color': '#'+(dataTask.status_id != undefined?listStatus[dataTask.status_id].hexa:'000') }"> {{(dataTask.status_id != undefined?listStatus[dataTask.status_id].name:'-')}}
                            </div>
                        </div>
                    </div>
                    <div class="task-fields">
                        <div class="task-field-label">
                            <span class="om-flags"></span>
                            <p>Priority</p>
                        </div>
                        <div @click="updatePriority($event,dataTask)" class="act_mini_btn">
                            <PriorityComponent
                            :task="dataTask"
                            />
                        </div>
                    </div>
                    <div class="task-fields">
                        <div class="task-field-label">
                            <span class="om-calendar"></span>
                            <p>Due Date</p>
                        </div>
                        <div @click="updateDueDate($event,dataTask)" class="act_mini_btn">
                            {{$filters.formatDate(dataTask.end,'MMM DD')}}
                        </div>
                    </div>
                    <div class="task-fields assign">
                        <div class="task-field-label">
                            <span class="om-users"></span>
                            <p>Assign</p>
                        </div>
                        <div @click="updateAssign($event,dataTask)" class="act_mini_btn">
                            <div v-for="(item,key) in dataTask.assignee" :key="key">
                                <span class="one-alfabet two-alfabet green">{{$filters.twoCharacter(item.name==''?item.email:item.name)}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="task-fields assign">
                        <div class="task-field-label">
                            <span class="om-tag"></span>
                            <p>Tags</p>
                        </div>
                        <div @click="updateTag($event,dataTask)" class="act_mini_btn">
                            <TagComponent
                            :list_tag="dataTask.tag"
                            />
                        </div>
                    </div>
                </div>
                <!-- <div class="tab_two">
                    <button><span class="om-chev-up"></span> Hide empty properties</button>
                </div> -->
                <div class="panel-list">
                    <div class="panel-item flex-12 taskDescription" style="overflow: hidden;">
                        <textarea v-model="dataTask.description" v-on:blur="save_update_desc" @input="resize()" ref="textareaDesc"></textarea>
                    </div>
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" :class="{'active':activeTabCenter == 'detail'}" @click.prevent="activeTabCenter = 'detail'" href="#">Detail</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{'active':activeTabCenter == 'sub_task'}" @click.prevent="activeTabCenter = 'sub_task'" href="#">Subtasks</a>
                        </li>
                    </ul>
                    <div class="nav-tab-list">
                        <div v-if="activeTabCenter == 'detail'">
                            <h5 class="taskViewHead">Custom Fields
                                <div @click="showActionCustomField($event,dataTask)" class="act_mini_btn">
                                    <span class="om-circle-add"></span>
                                </div>
                            </h5>
                            <div class="panel-item flex-12 taskCustomField">
                                <BaseCustomField
                                :dataCustomField="dataTask.custom_field"
                                :idTask="task_id"
                                @select-log="selectLogCustomField"
                                />
                            </div>
                            <h5>File Attach</h5>
                            <div class="panel-item flex-12">
                                <div class="container_custom_field list_file" style="display: flex;" v-if="dataTask.file_attach.length > 0">
                                    <div v-for="(item,key) in dataTask.file_attach" :key="key" @click="openPreview(item)">
                                        <GoogleDriveFilesComponent
                                        :files="item"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="panel-item flex-12">
                                <GoogleDriveComponent :type_layer="'full_width'" @response="addFiles"/>
                            </div>
                        </div>
                        <div v-if="activeTabCenter == 'sub_task'">
                            <h5>Sub task</h5>
                            <div class="panel-item flex-12 listView">
                                <div v-if="dataTask.child != undefined">
                                    <div class="content_list">
                                        <div class="vl_head">
                                            <div class="act"></div>
                                            <div class="name">Name</div>
                                            <div class="status">Assign</div>
                                            <div class="status">Due date</div>
                                            <div class="status">Priority</div>
                                            <div class="status task_status">Status</div> 
                                            <div class="status">Comments</div>
                                            <!-- <div class="status">Created By</div> -->
                                            <!-- <div class="status">Star Date</div> -->
                                            <!-- <div class="status">Date Created</div> -->
                                            <!-- <div class="status">Date Closed</div> -->
                                            <div class="act_2"><span class="om-home"></span></div>
                                        </div>
                                        <div class="vl_body">
                                            <ProjectListComponent 
                                            :list_child="dataTask.child" 
                                            :number_child="1"
                                            :parent_id="task_id"
                                            :status_data="listStatus"
                                            :column_data="dataTask" />
                                            <!-- <div class="list_row">
                                                <div class="list_column" v-if="dataTask.new_task == undefined">
                                                    <div class="act">
                                                    </div>
                                                    <div class="name">
                                                        <div class="add_task">
                                                            <span class="om-plus" @click="push_task(dataTask)"></span>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <button class="btn btn-outline-secondary">
                                                                        <p @click="push_task(dataTask)">Add Task</p>
                                                                        <span class="om-chev-down"></span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="status"></div>
                                                    <div class="status"></div>
                                                    <div class="status"></div>
                                                    <div class="act_2"></div>
                                                </div>
                                                <ProjectListAddComponent
                                                :data_status="item"
                                                :number_child="0"
                                                @set_child="onSetChild"
                                                v-if="dataTask.new_task != undefined"
                                                />
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-right" v-if="settingTabRight.show">
            <ActivityListComponent
            :data_activity="dataActivity"
            :data_task="dataTask"
            :data_user="listUser"
            v-if="settingTabRight.tabActive == 'activity'"
            />
            <ActivityListCommentComponent
            :data_comment="dataActivity.selectComment"
            :data_task="dataTask"
            :data_user="listUser"
            v-if="settingTabRight.tabActive == 'comment'"
            @back-activity="backActivityComment"
            />
            <ActivityListCustomFieldComponent
            :data_log="selectLog"
            v-if="settingTabRight.tabActive == 'custom_field'"
            @back-activity="backActivityComment"
            />
        </div>
        <div class="tab-menu">
            <div class="list_button" @click="tabRight('activity')" :class="{ active: settingTabRight.selectMenu == 'activity' }">
                <button>
                    <span class="om-comment"></span>
                </button>
                <p>Activity</p>
            </div>
        </div>
    </div>
</template>

<script>
    import eventBus from '@/services/eventBus';
    import Service from '@/services/Services';
    import ActivityListComponent from '@/components/space/task/ActivityListComponent.vue';
    import ActivityListCommentComponent from '@/components/space/task/ActivityListCommentComponent.vue';
    import ActivityListCustomFieldComponent from '@/components/space/task/ActivityListCustomFieldComponent.vue';
    import TagComponent from '@/components/statis/TagComponent.vue';
    import PriorityComponent from '@/components/statis/PriorityComponent.vue';
    import ProjectListComponent from '@/components/space/ProjectListComponent.vue';
    import BaseCustomField from '@/components/space/custom_field/BaseCustomField.vue';
    import GoogleDriveFilesComponent from '@/components/statis/GoogleDriveFilesComponent.vue';
    import GoogleDriveComponent from '@/components/statis/GoogleDriveComponent.vue';
    import BreadCrumbComponent from '@/components/statis/BreadCrumbComponent.vue';
    export default {
        name: 'TaskView',
        props: ['task_code'],
        components: {
            PriorityComponent,
            ActivityListComponent,
            ActivityListCommentComponent,
            ActivityListCustomFieldComponent,
            BaseCustomField,
            GoogleDriveFilesComponent,
            ProjectListComponent,
            TagComponent,
            BreadCrumbComponent,
            GoogleDriveComponent
        },
        data: function() {
            return {
                showTabLeft: false,
                settingTabRight:{
                    show:true,
                    loadFirst: 'activity',
                    tabActive: 'activity',
                    selectMenu: 'activity'
                },
                activeTabCenter:'detail',
                task_id: '',
                dataTask: {
                    file_attach:[]
                },
                selectLog: {},
                dataActivity: {
                    list:[],
                    selectComment: {}
                },
                listStatus: {},
                dataBread:{},
                listUser:[]
            };
        },
        created: function() {
        },
        unmounted () {
        },
        methods: {
            getTask(){
                var thos = this;
                Service.post(this.$site_url+'task/data',{idTask:this.task_id}).then((response) => {
                    if(response.status == 'ok'){
                        thos.dataTask = response.data;
                        thos.getSubTask();
                    }
                    if (thos.settingTabRight.loadFirst == 'activity') {
                        Service.post(this.$site_url+'task/get_activity',{idTask:this.task_id}).then((response) => {
                            if(response.status == 'ok'){
                                thos.dataActivity.list = response.data;
                            }
                        });
                    }
                });
            },
            getSubTask(){
                var thos = this;
                Service.post(this.$site_url+'task/get_sub',{parentId:this.task_id}).then((response) => {
                    if(response.status == 'ok'){
                        // params.child = response.data;
                        thos.dataTask.child = response.data;
                        console.log(thos.dataTask);
                    }
                });
            },
            tabRight(type = ''){
                var thos = this;
                this.settingTabRight.show = !this.settingTabRight.show;
                this.settingTabRight.tabActive = type;
                this.settingTabRight.selectMenu = type;
                if (type == 'activity') {
                    if (this.settingTabRight.show) {
                        Service.post(this.$site_url+'task/get_activity',{idTask:this.task_id}).then((response) => {
                            if(response.status == 'ok'){
                                thos.dataActivity.list = response.data;
                            }
                        });
                    }
                }
                if (!this.settingTabRight.show) {
                    this.settingTabRight.tabActive = '';
                    this.settingTabRight.selectMenu = '';
                }
            },
            addFiles(data){
                var attach = data;
                if(data.length > 0){
                    Service.post(this.$site_url+'task/update',{idTask:this.dataTask.code,attach:JSON.stringify(attach),type:'attach'}).then((res) => {
                        if (res.status == 'ok') {
                            this.dataTask.file_attach = res.data.file_attach;
                        }
                    });
                }
                // console.log('task upload',data);
            },
            save_update_desc(){
                Service.post(this.$site_url+'task/update',{idTask:this.dataTask.code,description:this.dataTask.description,type:'description'}).then(() => {});
            },
            closeModal(){
                console.log('s');
                eventBus.$emit('close-modal-task', {});
            },
            backActivityComment(){
                this.settingTabRight.tabActive = 'activity';
            },
            updateAssign(el,params){
                // params.canUpdate = true;
                eventBus.$emit('openDropdown', {target:el,
                    component:'AssignComponent',
                    data:params
                });
            },
            updateTag(el,params){
                // params.canUpdate = true;
                eventBus.$emit('openDropdown', {target:el,
                    component:'TagComponent',
                    data:params
                });
            },
            updatePriority(el,params){
                params.canUpdate = true;
                eventBus.$emit('openDropdown', {target:el,
                    component:'PriorityComponent',
                    data:params
                });
            },
            updateStatus(el,params){
                params.canUpdate = true;
                eventBus.$emit('openDropdown', {target:el,
                    component:'StatusComponent',
                    data:params
                });
            },
            updateDueDate(el,params){
                params.canUpdate = true;
                eventBus.$emit('openDropdown', {target:el,
                    component:'DueDateComponent',
                    data:params
                });
            },
            showActionCustomField(el,params){
                eventBus.$emit('openDropdown', {target:el,
                    component:'ActionAddCfComponent',
                    data:params
                });
            },
            selectLogCustomField(params){
                this.selectLog = params;
                this.settingTabRight.tabActive = 'custom_field';
            },
            resize() {
                let element = this.$refs["textareaDesc"];
                element.style.height = "18px";
                element.style.height = element.scrollHeight + "px";
            },
            openPreview(item){
                eventBus.$emit('open-modal-preview', item);
            },
            getSpace(){
                var thos = this;
                eventBus.$emit('get-global-space',{},(response) => {
                    var dataIdProject = localStorage.getItem("idProject");
                    var dataSpace = structuredClone(response);
                    dataSpace.map((v)=>{
                        v.child.map((v_child)=>{
                            if (dataIdProject == v_child.id) {
                                thos.dataBread.space = v;
                                thos.dataBread.project = v_child;
                            }
                        })
                    });
                });
            },
            getUser(){
                var thos = this;
                eventBus.$emit('get-global-user',{},(response) => {
                    thos.listUser = structuredClone(response);
                });
            }
        },
        mounted(){
            var thos = this;
            var dataStatus = localStorage.getItem("dataStatus");
            dataStatus = JSON.parse(dataStatus);
            dataStatus.map((v)=>{
                this.listStatus[v.id] = v;
            })
            setTimeout(function() {
                thos.getSpace();
                thos.getUser();
            }, 100);
            setTimeout(function() {
                if (thos.task_code == undefined) {
                    thos.task_id = thos.$route.params.idTask;
                    thos.getTask();
                    eventBus.$emit('hide-side', true);
                }else{
                    thos.task_id = thos.task_code;
                    thos.getTask();
                }
            }, 100);

            // document.getElementById("headerTask").focus();

            eventBus.$on('task-update-custom-field', function (data) {
                thos.dataTask.custom_field = data;
            });

            eventBus.$on('taskView-reply', function (paramComment) {
                thos.dataActivity.selectComment = paramComment;
                thos.settingTabRight.tabActive = 'comment';

                if (paramComment.comment.chat.length != paramComment.comment.chat_number) {
                    Service.post(thos.$site_url+'comment/get_chat_child',{commentId:paramComment.comment.id}).then((response) => {
                        if(response.status == 'ok'){
                            paramComment.comment.chat = response.data;
                        }
                    });
                }
            });
        },
        beforeRouteLeave(){
            setTimeout(function() {
                eventBus.$emit('hide-side', false);
            }, 10);
        }
    }
</script>
