<template>
    <div class="modal-body">
        <div class="list-priority flex" v-for="(item,key) in listStatus" :key="key" @click="setStatus(item.id)">
            <span class="om-prog-0" :style="{'color':'#'+item.hexa}"></span>
            <span>{{item.name}}</span>
        </div>
    </div>
</template>
<script>
    import Service from '@/services/Services';
    import eventBus from '@/services/eventBus';
    export default {
        props: ['key_dropdown','dataParam'],
        data: function() {
            return {
                newTask: {
                    end: this.dataParam.date,
                },
                muParams: this.dataParam,
                listStatus: [],
                selectNumber:'',
            };
        },
        methods: {
            save_update_task(){
                var thos = this;
                if (this.muParams.canUpdate != undefined) {
                    Service.post(this.$site_url+'task/update',{idTask:this.muParams.code,idStatus:this.selectNumber,type:'status'}).then((response) => {
                        if(response.status == 'ok'){
                            // console.log(thos.muParams);
                            thos.muParams.status_id = thos.selectNumber;
                            thos.$emit('close',thos.key_dropdown);
                            eventBus.$emit('task-move-status', {});
                        }else{
                            eventBus.$emit('toast-on', response.message ,'warning');
                            thos.$emit('close',thos.key_dropdown);
                        }
                    });
                }else{
                    thos.$emit('close',thos.key_dropdown);
                }
            },
            setStatus(number = null){
                this.selectNumber = number;
                console.log(this.selectNumber);
                var thos = this;
                setTimeout(function() {
                    thos.save_update_task();
                }, 10);
            }
        },
        mounted(){
            console.log('ss',this.dataParam);
            var dataStatus = [];
            if (this.dataParam.dataStatus != undefined) {
                var dataRaw = this.dataParam.dataStatus;
                Object.keys(dataRaw).map((v)=>{
                    if (dataRaw[v].project_id == null || dataRaw[v].project_id == this.dataParam.project_id) {
                        dataStatus.push(dataRaw[v]);
                    }
                })
                this.listStatus = dataStatus;
            }else{
                dataStatus = localStorage.getItem("dataStatus");
                this.listStatus = JSON.parse(dataStatus);
            }
        },
    };
</script>