<template>
  <div v-if="type_layer == undefined">
    <span class="om-document cursor-pointer" @click="driveIconClicked()"></span>
  </div>
  <div v-if="type_layer == 'full_width'" class="container_custom_field list_file" style="display: flex;align-items: center;cursor: pointer;" @click="driveIconClicked()">
    add Files <span class="om-document cursor-pointer" style="margin-left: 4px"></span>
  </div>
</template>
<script>
  import Service from '@/services/Services';
  // import eventBus from '@/services/eventBus';
  export default {
    props: ['type_layer'],
    data: function() {
      return {
        googlePicker:{
          pickerApiLoaded: false,
          developerKey: this.$api_key,
          clientId: this.$client_id,
          scope: "https://www.googleapis.com/auth/drive",
          oauthToken: null
        },
      };
    },
    methods: {
      createPicker() {
        // console.log("Create Picker", window.google.picker,this.googlePicker);
        if (this.googlePicker.pickerApiLoaded && this.googlePicker.oauthToken != null) {
          var uploadView = new window.google.picker.DocsUploadView();

          var picker = new window.google.picker.PickerBuilder()
          .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
          .addView(window.google.picker.ViewId.DOCS)
          .addView(uploadView)
          .setOAuthToken(this.googlePicker.oauthToken)
          .setDeveloperKey(this.googlePicker.developerKey)
          .setCallback(this.pickerCallback)
          .build();
          picker.setVisible(true);
        }
      },
      async driveIconClicked() {
                // console.log("Clicked");
        await window.gapi.load("auth2", () => {
                    // console.log("Auth2 Loaded");
          window.gapi.auth2.authorize(
          {
            client_id: this.googlePicker.clientId,
            scope: this.googlePicker.scope,
            immediate: false,
            plugin_name: "Task Management Local"
          },
          this.handleAuthResult
          );
        });
        window.gapi.load("picker", () => {
          this.googlePicker.pickerApiLoaded = true;
          this.createPicker();
        });
      },
      handleAuthResult(authResult) {
        // console.log("Handle Auth result", authResult);
        if (authResult && !authResult.error) {
          this.googlePicker.oauthToken = authResult.access_token;
          this.createPicker();
        }
      },
      // async driveIconClicked() {
      //   var thos = this;
      //   Service.get(this.$site_url+'member/data',{}).then((response) => {
      //     if(response.status == 'ok'){
      //       if (response.data.connect_google != null) {
      //         thos.googlePicker.oauthToken = response.data.connect_google;
      //         window.gapi.load("picker", () => {
      //           this.googlePicker.pickerApiLoaded = true;
      //           this.createPicker();
      //         });
      //       }else{
      //         eventBus.$emit('open-modal-setting', true);
      //       }
      //     }
      //   });
      // },
      async getThumbnail(drive,fileId) {
        // try {
        //   const response = await drive.files.get({
        //     fileId: fileId,
        //     fields: 'thumbnailLink'
        //   });
        //   return response.data.thumbnailLink;
        // } catch (error) {
        //   console.error('Error retrieving thumbnail:', error);
        // }

        console.log(drive,fileId);
        Service.get('https://drive.google.com/thumbnail?authuser='+this.googlePicker.oauthToken+'&sz=w320&id='+fileId,{}).then((response) => {
          console.log(response,drive);
        })

        // return window.gapi.client.drive.files.get({
        //   fileId: fileId,
        //   fields: 'thumbnailLink'
        // }).then(function(response) {
        //   console.log("Thumbnail Link:", response.result.thumbnailLink);
        // }, function(err) {
        //   console.error("Error retrieving thumbnail link", err);
        // });
      },
      async pickerCallback(data) {
        console.log("PickerCallback", data);
        var doc = "";
        var fileID = "";
        if (data[window.google.picker.Response.ACTION] == window.google.picker.Action.PICKED) {
          var gdurl = "";
          var type = "anyone";
          var role = "reader";
          for(var i = 0; i < data[window.google.picker.Response.DOCUMENTS].length; i++){
            doc = data[window.google.picker.Response.DOCUMENTS][i];
            gdurl = gdurl + " " + doc[window.google.picker.Document.URL];

            //change the file permissions to share with anyone with the link
            fileID = doc[window.google.picker.Document.ID];
            var request1 = window.gapi.client.request({
              'path': '/drive/v3/files/' + fileID + '/permissions',
              'method': 'POST',
              'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.googlePicker.oauthToken
              },
              'body':{
                'role': role,
                'type': type
              }
            });
            request1.execute(function(resp) {
              console.log('upload file',resp);
            });
          }
        }


        var data_return = [];
        if (data.action == 'picked') {
          data.docs.map((v)=>{
            var newFiles = {name:v.name,id:v.id,url:v.url,embed:v.embedUrl,type:'document'};
            // const drive = window.google.drive({ version: 'v3', auth: this.googlePicker.oauthToken });
            // newFiles.embed = this.getThumbnail('',v.id);
            if (v.uploadState != undefined) {
              if (['video','photo'].includes(v.type)) {
                // newFiles.embed = "https://lh3.googleusercontent.com/d/"+v.id+"=w320?authuser=0";
                newFiles.embed = v.url.replace("view", "preview");
              }
            }
            if (['video','photo'].includes(v.type)) {
              newFiles.type = v.type;
            }
            data_return.push(newFiles);
          });
        }

        console.log('upload_files',data_return);
        this.$emit('response',data_return);
      }
    }
  };
</script>